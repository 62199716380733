@import url('https://fonts.googleapis.com/css2?family=Darker+Grotesque:wght@400;500;700&family=Inter:wght@400;500;700&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  font-family: 'Inter', sans-serif;
}

.font-darker-grotesque {
  font-family: 'Darker Grotesque', sans-serif;
}

/* Base styles for the nav items */
.nav-item {
  position: relative;
  cursor: pointer;
  font-family: "Darker Grotesque", Helvetica Neue, Helvetica, Arial, sans-serif;
  font-size: 20px;
  line-height: 1;
  font-weight: 700;
  transition: color .2s ease;
}

/* Remove dots on small screens */
.nav-item::before {
  content: '';
  position: absolute;
  top: -6px;
  left: 50%;
  transform: translateX(-50%);
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background-color: transparent;
  transition: background-color 0.3s;
}

/* Hover and active states for medium and larger screens */
@media (min-width: 768px) {
  .nav-item:hover::before {
    background-color: black;
  }

  .nav-item.active::before {
    background-color: #FEC824;
  }

  .nav-item.active:hover::before {
    background-color: #FEC824;
  }
}

/* Styles for markdown content */
.markdown-content {
  padding-left: 1rem;  /* 4px on small screens */
  padding-right: 1rem;
  margin-left: auto;
  margin-right: auto;
}

@media (min-width: 640px) {
  .markdown-content {
    padding-left: 1.5rem;  /* 6px on medium screens */
    padding-right: 1.5rem;
  }
}

@media (min-width: 768px) {
  .markdown-content {
    padding-left: 2rem;  /* 8px on large screens */
    padding-right: 2rem;
  }
}

.markdown-content h1 {
  font-size: 1.17em; /* Adjust size as needed */
  font-weight: bold;
  margin-top: 0rem;
  margin-bottom: 0.5rem;
}

.markdown-content h2 {
  font-size: 1.5em; /* Adjust size as needed */
  font-weight: bold;
  margin-top: 1.25rem;
  margin-bottom: 0.5rem;
}

.markdown-content h3 {
  font-size: 1.17em; /* Adjust size as needed */
  font-weight: bold;
  margin-top: 1.25rem;
  margin-bottom: 0.5rem;
}

.markdown-content h4 {
  font-size: 1em; /* Adjust size as needed */
  font-weight: bold;
  margin-top: 1rem;
  margin-bottom: 0.4rem;
}

.markdown-content p {
  margin-bottom: 0.5rem;
}

.markdown-content strong {
  font-weight: bold;
}

.markdown-content ul,
.markdown-content ol {
  margin-left: 1.5rem;
  margin-bottom: 0.15rem;
  list-style-position: outside; /* Ensure the list style position is set correctly */
}

.markdown-content ul {
  list-style-type: disc; /* Ensure unordered lists have disc bullets */
}

.markdown-content ol {
  list-style-type: decimal; /* Ensure ordered lists have decimal numbers */
}

.markdown-content li {
  margin-bottom: 0.25rem; /* Add some margin to list items for better spacing */
}

.markdown-content a {
  text-decoration: underline;
 /* text-decoration-style: dashed; /* This adds a dotted underline */
}

.markdown-content a:hover {
  text-decoration: underline;
  text-decoration-style: solid; /* Change to a solid underline on hover, if desired */
}

.underline-dashed {
  text-decoration: underline;
  /*text-decoration-style: dashed;*/
}

.markdown-content blockquote {
  border-left: 4px solid #ccc; /* Left border color */
  padding-left: 16px;           /* Padding inside the blockquote */
  padding-right: 16px;
  margin: 16px 0;               /* Margin around the blockquote */
  display: inline-block;
  width: auto;
  max-width: 100%;
}

.markdown-content blockquote p {
  margin: 0;                    /* Remove default paragraph margin */
}

input[type="radio"], input[type="checkbox"] {
  cursor: pointer;
}

.newsletter-page {
  padding: 0px; /* Add some padding for spacing */
  max-width: 800px; /* Limit the maximum width of the page */
  text-align: left; /* Center the text inside */
}

.newsletter-page h2 {
  font-size: 2rem;
  margin-bottom: 1rem;
}

.newsletter-page p {
}

.email-input {
  padding: 0.5rem;
  border: 1px solid #ccc;
  border-radius: 5px;
  width: 100%;
  min-width: 250px;
  max-width: 300px; /* Limit the width of the input field */
  margin-bottom: 1rem;
}

.subscribe-button {
  border: none;
  padding: 0.5rem 1rem;
  cursor: pointer;
}

.message {
  margin-top: 1rem;
  color: green;
}

.play-icon-overlay {
  position: absolute;
  inset: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: none;
  z-index: 10; /* Ensure the play icon is above the image */
}

.play-icon-background {
  background-color: rgba(0, 0, 0, 0.5);
  padding: 0.5rem; /* Adjust padding if needed */
  border-radius: 9999px; /* Makes the background circular */
}

.play-icon-svg {
  height: 4rem; /* Equivalent to h-16 */
  width: 4rem; /* Equivalent to w-16 */
  color: white;
  z-index: 20; /* Ensure the SVG is above the background */
}

/* Compound class for the card container */
.card-container {
  position: relative;
  overflow: hidden;
  border-radius: 0.5rem; /* lg equivalent */
  padding: 1rem; /* 4 equivalent */
  box-shadow: 0px -4px 8px rgba(0, 0, 0, 0.1), 0px 4px 8px rgba(0, 0, 0, 0.1);
}

/* Compound class for the card image */
.card-image {
  width: 100%;
  height: auto;
  object-fit: cover;
  transition: transform 0.3s ease-in-out;
  cursor: pointer;
}

.card-image:hover {
  transform: scale(1.03);
}

/* Heading styles */
.card-heading {
  font-size: 1.5rem; /* 2xl equivalent */
  font-weight: bold;
  margin: 1rem 0; /* my-4 equivalent */
}

/* Paragraph styles */
.card-paragraph {
  font-size: 1.125rem; /* lg equivalent */
  margin-top: 1rem; /* mt-4 equivalent */
}

.candidate-card {
  background-color: white;
  padding: 1.5rem;
  border-radius: 0.75rem;
  box-shadow: 0px -4px 8px rgba(0, 0, 0, 0.1), 0px 4px 8px rgba(0, 0, 0, 0.2);
  position: relative;
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
  cursor: pointer;
}

.candidate-card:hover {
  transform: scale(1.05);
  box-shadow: 0px -4px 12px rgba(0, 0, 0, 0.15), 0px 8px 24px rgba(0, 0, 0, 0.3);
}

.candidate-card img {
  width: 12rem; /* Equivalent to w-48 */
  height: 12rem; /* Equivalent to h-48 */
  border-radius: 9999px; /* Equivalent to rounded-full */
  margin-bottom: 1rem; /* Equivalent to mb-4 */
}

.candidate-card h2 {
  margin-bottom: 0.5rem; /* Equivalent to mb-2 */
}

.candidate-card h3 {
  margin-bottom: 0.5rem; /* Equivalent to mb-2 */
  color: #4B5563; /* Equivalent to text-gray-600 */
}

.candidate-card p {
  color: #4A5568; /* Equivalent to text-gray-700 */
}

.candidate-card .absolute {
  top: 0.75rem; /* Equivalent to top-3 */
  right: 0.75rem; /* Equivalent to right-3 */
}

.candidate-card .party-logo {
  width: 6rem; /* Fixed size for the logo, adjust as needed */
  height: 6rem;
  margin-left: 0.35rem;
}

.markdown-content table {
  width: 100%;
  border-collapse: collapse;
  margin: 1em 0;
  font-size: .9rem;
}

.markdown-content th,
.markdown-content td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

.markdown-content th {
  background-color: #f2f2f2;
  color: #333;
}

.markdown-content img {
  max-width: 80%;  /* Ensure image doesn't exceed container width */
  height: auto;     /* Maintain aspect ratio */
}

@media (max-width: 768px) {
  .markdown-content img {
    max-width: 70%;  /* Slightly reduce image size on small screens */
    margin: 0 auto;  /* Center the image */
    display: block;  /* Ensure block display for better layout */
  }
}

.bullet {
  list-style-position: inside; /* Ensures bullet is inside the padding area */
  padding-left: 0; /* No padding on the left */
  margin-left: 0; /* Reduce the space between bullet and text */
}
